.spinner {
  width: 100%;
  text-align: center;
}

table {
  margin: 50px auto 0;
  width: 100%;
  max-width: 800px;
}

table th {
  text-align: left;
  width: 25%;
}